<template>
    <div class="w3-theme-l1 w3-row-padding w3-margin-top" style="">
		<div class="w3-col l5">
			<div class="w3-card-4 w3-white">
				<div class="w3-theme-dark w3-padding" style="position: relative;height: 38px;">
					Pelayanan
					<button title="Reload Pelayanan" @click="loadPelayanan" type="button" class="w3-btn w3-blue" style="position: absolute; top: 0px; right: 0px; cursor: pointer;"><i class="fa fa-refresh"></i></button>
				</div>
				<div style="padding-bottom: 5px;">
					<ul class="w3-ul w3-margin w3-border w3-small">
						<li v-if="loading.pelayanan==true">
							<DeLoading :show="true"></DeLoading>
						</li>
						<li v-else v-on:click="loadPelayananJadwal(dtl, index);" v-for="(dtl, index) in recPelayanans" :key="index" class="w3-hover-green"
							style="cursor: pointer;" >
							<i :class="pilihPelayanan==index ? 'fa fa-check-square-o w3-text-blue':'fa fa-minus-square w3-text-red'" class="w3-large"></i>
							{{dtl.pelayanan_name}}
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="w3-col l7">
			<div class="w3-card-4 w3-white">
				<div class="w3-theme-dark w3-padding" style="position: relative;height: 38px;">
					Jadwal
					<button title="Add Jadwal" @click="addPelayanan" type="button" class="w3-btn w3-blue" style="position: absolute; top: 0px; right: 0px; cursor: pointer;"><i class="fa fa-plus"></i></button>
				</div>
				<div style="padding-bottom: 5px;">
					<vue-scrolling-table v-bind:style="'height:'+  (screenHeight-149) +'px;'" :class="'w3-table w3-striped w3-bordered w3-white w3-small freezeFirstColumn'" :include-footer="true" :dead-area-color="'w3-theme'" :scroll-horizontal="false" id="grid">
						<template slot="thead">
							<tr class="w3-border-right">
								<th style="min-width:50px;" class="w3-light-grey w3-border-0">
									No
								</th>
								<th style="min-width:200px;" class="w3-light-grey w3-border-0" >
									Petugas
								</th>
								<th style="min-width:100px;" class="w3-light-grey w3-border-0" >
									Hari
								</th>
								<th style="min-width:50px;" class="w3-light-grey w3-border-0" >
									Mulai
								</th>
								<th style="min-width:60px;" class="w3-light-grey w3-border-0" >
									Selesai
								</th>
								<th style="min-width:80px;" class="w3-light-grey w3-border-0" >
									Online
								</th>
								<th style="min-width:80px;" class="w3-light-grey w3-border-0" >
									Ofline
								</th>
								<th style="min-width:130px;" class="w3-light-grey w3-border-0" >
									
								</th>
							</tr>
						</template>
						<template slot="tbody">
							<tr @mouseover="dtl['pilih']=true" @mouseleave="dtl['pilih']=false" v-for="(dtl, index) in recJadwals"  style="cursor: pointer;" :class="(dtl.pilih==true?'bayangan w3-theme-l4':(index == pilihJadwal ?'bayangan w3-theme-l4':'w3-opacity-min'))"  :key="index" class="w3-text-black">
								<td style="min-width:50px;" class="w3-light-grey w3-border-0 w3-border-right">
									<i :class="index == pilihJadwal ?'fa fa-caret-right':''"></i>{{index+1}}
								</td>
								<td v-on:click="pilihJadwal=index" style="min-width:200px;padding: 0px!important;" class="w3-border-0 w3-border-right">
									<select v-model="dtl.petugas_code" class="w3-select w3-small w3-border-0 w3-white">
										<option  v-for="(dtl, index) in recPetugas" :value="dtl.petugas_code" :key="index">{{dtl.petugas_name}}</option>
									</select>
								</td>
								<td v-on:click="pilihJadwal=index" style="min-width:100px;padding: 0px!important;" class="w3-border-0 w3-border-right">
									<select v-model="dtl.pelayanan_jadwal_hari" class="w3-select w3-small w3-border-0 w3-white">
										<option value="1">Minggu</option>
										<option value="2">Senin</option>
										<option value="3">Selasa</option>
										<option value="4">Rabu</option>
										<option value="5">Kamis</option>
										<option value="6">Jumat</option>
										<option value="7">sabtu</option>
									</select>
								</td>
								<td v-on:click="pilihJadwal=index" style="min-width:50px;padding: 0px!important;" class="w3-border-0">
									<input v-mask="'##:##'" v-model="dtl.pelayanan_jadwal_jam_mulai" class="w3-input w3-small w3-border-0" placeholder="00:00"/>
								</td>
								<td v-on:click="pilihJadwal=index" style="min-width:60px;padding: 0px!important;" class="w3-border-0">
									<input v-mask="'##:##'" v-model="dtl.pelayanan_jadwal_jam_selesai" class="w3-input w3-small w3-border-0" placeholder="00:00"/>
								</td>
								<td v-on:click="pilihJadwal=index" style="min-width:80px;padding: 0px!important;" class="w3-border-0">
									<input v-mask="'##'" v-model="dtl.pelayanan_jadwal_kapasitas_online" class="w3-input w3-small w3-border-0" placeholder="Kapasitas Online"/>
								</td>
								<td v-on:click="pilihJadwal=index" style="min-width:80px;padding: 0px!important;" class="w3-border-0">
									<input v-mask="'##'" v-model="dtl.pelayanan_jadwal_kapasitas_ofline" class="w3-input w3-small w3-border-0" placeholder="Kapasitas Ofline"/>
								</td>
								<td style="min-width:130px;padding: 0px!important;" class="w3-border-0 w3-border-right">
									<button @click="saveData(dtl)" type="button" class="w3-btn w3-small w3-theme"><i class="fa fa-save"></i></button>
									<button title="Proses jam otomatis" @click="showJam(index, dtl)" type="button" class="w3-btn w3-small w3-blue"><i class="fa fa-gears"></i></button>
									<button @click="removeDetail(index, dtl)" type="button" class="w3-btn w3-small w3-red"><i class="fa fa-trash"></i></button>
								</td>
							</tr>
						</template>
					</vue-scrolling-table>
				</div>
			</div>
		</div>
		<div id="id01" class="w3-modal" >
			<vue-draggable-resizable :y="50" :drag-handle="'.drag'" style="width:100%;">
				<div class="w3-modal-content w3-animate-zoom w3-card-4" style="max-width:500px;">   
					<header class="w3-container w3-theme-d3 drag" style="cursor:move;"> 
						<span onclick="document.getElementById('id01').style.display='none'" 
						class="w3-button w3-display-topright">&times;</span>
						<h6><i class="fa fa-windows"></i> Proses Jam</h6>
					</header>
					<div class="w3-container w3-padding w3-theme-l4 ">
						<button class="w3-btn w3-block w3-theme" type="button"><i class="fa fa-save"></i> Save Jam</button>
						<div style="max-height: 400px;margin-top: 5px;margin-bottom: 5px;" class="w3-responsive">
							<ul class="w3-ul w3-border w3-small w3-white">
								<li v-if="loading.waktu==true">
									<DeLoading :show="true"></DeLoading>
								</li>
								<li v-else v-for="(dtl, index) in recWaktus" :key="index" class="w3-hover-green"
									style="cursor: pointer;" >
									No Antrian <b>{{index+1}}</b>
									Jam tindakan <b>{{dtl.jam}}</b>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</vue-draggable-resizable>
		</div>
    </div>
</template>
<script>
/* eslint-disable */

import moment from "moment";
export default {
    head: {
        title: {
          inner: 'Jadwal'
        },
    },
    name: 'Jadwal',
    components: {},
    data() {
        return {
			recPelayanans:[],
			loading: {pelayanan: false, jadwal: false, waktu:false},
			pilihPelayanan: -1,
			recJadwals: [],
			pilihJadwal: -1,
			screenHeight : 0,
			recPetugas: [],
			recWaktus: []
        };
    },
    methods : {
        loadPelayanan(){
			const self = this;
			self.loading.pelayanan = true;
			self.recPelayanans = [];
			self.pilihPelayanan = -1;
			self.apiPost("/antrian/pelayanan/search",{},function(res){
				if(res.success){
					self.recPelayanans = res.rec;
				}
				self.loading.pelayanan = false;
			},function(err){
				self.loading.pelayanan = false;
				console.log(err);
			});
		},
		loadPelayananJadwal(dtl, index){
			const self = this;
			self.pilihPelayanan = index;
			self.pilihJadwal = -1;
			self.recJadwals = [];
			self.apiPost("/antrian/pelayananjadwal/search",{
				operator: "AND",
				kondisi: "=",
				field: "pelayanan_jadwal.pelayanan_code",
				fieldvalue: dtl.pelayanan_code,
				limit: 100,
				offset: 0,
			},function(res){
				if(res.success){
					res.rec.map(function(val){
						val["pelayanan_jadwal_code_old"] = val["pelayanan_jadwal_code"];
					});
					self.recJadwals = res.rec;
				}
			},function(err){
				console.log(err);
			});
		},
		setWaktu(){
			const self = this;
			self.recWaktus = [];
			let waktu = new Date("2021-01-01 08:00");
			for(let bil = 0; bil<100; bil++){
				self.recWaktus.push(
					{
						"no": bil+1,
						"jam": moment(waktu).format('HH:mm')
					}
				);
				waktu = self.incTime(waktu,15);
			}
		},
		loadPetugas() {
			const self = this;
			self.apiPost("/antrian/petugas/search",{},function(res){
				if(res.success){
					self.recPetugas = res.rec;
				}
			},function(err){
				console.log(err);
			});
		},
		addPelayanan() {
			const self = this;
			let rec = self.recPelayanans[self.pilihPelayanan];
			self.recJadwals.push({
				pelayanan_jadwal_code_old : "",
				pelayanan_jadwal_code : "",
				pelayanan_code : rec.pelayanan_code,
				petugas_code : "",
				pelayanan_jadwal_hari : "1",
				pelayanan_jadwal_jam_mulai : "08:00",
				pelayanan_jadwal_jam_selesai : "17:00",
				pelayanan_jadwal_kapasitas_ofline : "1",
				pelayanan_jadwal_kapasitas_online : "1",
				pelayanan_jadwal_status: "T",
			})
		},
		savePelayanan(){
			const self = this;
			let rec = self.recPelayanans[self.pilihPelayanan];
			rec["pelayanan_waktu"] = JSON.stringify(self.recWaktus);
			rec["pelayanan_code_old"] = rec["pelayanan_code"];
			self.apiPost("/antrian/pelayanan/save",rec,function(res){
				if(res.success){
					self.$toast.success("Sukses Simpan data");
				}else{
					self.$toast.error(res.msg);
				}
			},function(err){
				console.log(err);
			});
		},
		saveData(dtl) {
			const self = this;
			if(dtl.petugas_code==""){
				self.$toast.error("Isi Petugas dulu ...");
				return false;
			}
			self.apiPost("/antrian/pelayananjadwal/save", dtl, function(res){
				if(res.success){
					if(dtl["pelayanan_jadwal_code_old"]==""){
						dtl.pelayanan_jadwal_code = res.kode;
						dtl.pelayanan_jadwal_code_old = res.kode;
					}
					self.$toast.success("Sukses simpan data");
				}
			}, function(err){
				console.log(err);
			});
		},
		removeDetail(index, dt) {
			const self = this;
			swal({
			    title: "Yakin Ingin Menghapus Data ..!",
			    text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
			    type: "warning",
			    showCancelButton: true,
			    confirmButtonColor: "#DD6B55",
			    confirmButtonText: "Ya, Hapus Data",
			    cancelButtonText: "Tidak, Batal Hapus",
			    closeOnConfirm: true,
			    closeOnCancel: true
			},function(isConfirm){
			    if (isConfirm) {
			        let vparams = {pelayanan_jadwal_code_old : dt.pelayanan_jadwal_code};
			        self.apiPost('/antrian/pelayananjadwal/delete' ,vparams,function(response){
			            if(response.success==true){
			                self.$toast.success('Data Sudah terhapus');
			                self.$delete(self.recJadwals,index);
			            }else{
			                self.$toast.error(response.msg);
			            }
			        });                    
			    }
			});
		},
		showJam(index, dtl) {
			const self = this;
			document.getElementById('id01').style.display='block';
		}
    },
    computed :{
        
    },
    filters: {
        
    },
    watch: {
        '$route': function(){
        }
    },
    mounted(){
        let self = this;
        this.screenHeight = window.innerHeight;
        this.loadPelayanan();
		this.loadPetugas();
		this.screenHeight = window.innerHeight;
		this.setWaktu();
    }
};
</script>